.reactLineItemTable .ReactTable .rt-tbody {
  overflow-y: scroll !important;
}

.storeIndentTable .ReactTable .rt-thead .rt-th input {
  display: none;
}

.workReportTable .ReactTable .rt-thead .rt-th input {
  display: none;
}
.wo-po-Table .ReactTable .rt-thead .rt-th input {
  display: none;
}

.inwardTable .ReactTable .rt-thead .rt-th input {
  display: none;
}

.materialInspectionTable .ReactTable .rt-thead .rt-th input {
  display: none;
}

.storeReceiptTable .ReactTable .rt-thead .rt-th input {
  display: none;
}

.billpassTable .ReactTable .rt-thead .rt-th input {
  display: none;
}

.bom-boqTable .ReactTable .rt-thead .rt-th input {
  display: none;
}
.retiredDatePicker .rdtPicker {
  margin-top: -150px !important;
}
